import { Controller, FormProvider } from "react-hook-form";

import { useFormFeedBackStyles } from "./FormFeedBackStyles";
import { useFormFeedback } from "./useFormFeedBack";

import NameInputSC from "./NameInputSC";
import EmailInputSC from "./EmailInputSC";
import TheneInputSC from "./TheneInputSC";

const FormFeedBack = ({ isOpenForm, closeForm }) => {
  const { method, handleSubmit, onSubmit } = useFormFeedback(closeForm);

  return (
    // <ExternalWrapperSC>
    <SectionSC $isShow={isOpenForm}>
      <ExitButton onClick={closeForm} />
      <TitleSC>Обратная связь</TitleSC>
      <FormProvider {...method}>
        <FormSC action="#" onSubmit={handleSubmit(onSubmit)}>
          <NameInputSC />
          <EmailInputSC />
          <TheneInputSC />
          <ButtonSC type="submit">Отправить</ButtonSC>
        </FormSC>
      </FormProvider>
    </SectionSC>
    // </ExternalWrapperSC>
  );
};

const { SectionSC, FormSC, TitleSC, ButtonSC, ExitButton } =
  useFormFeedBackStyles();

export default FormFeedBack;
