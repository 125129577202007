// @ts-nocheck

import React, { useState } from "react";
import { useFormFeedback } from "../../FormFeedBack/useFormFeedBack";
import { FormProvider } from "react-hook-form";
import NameInputSC from "../../FormFeedBack/NameInputSC";
import EmailInputSC from "../../FormFeedBack/EmailInputSC";
import TheneInputSC from "../../FormFeedBack/TheneInputSC";
import styled from "styled-components";
import useStore from "../../../store";

const FormBuy = (props) => {
  const [state, setState] = useState(false);
  const { removeAll } = useStore();
  const { method, handleSubmit, onSubmit } = useFormFeedback();

  const handleChangeState = () => {
    setState(true);
    removeAll();

    setTimeout(() => {
      props.handlePreviewStep();
    }, 2000);
  };

  return (
    // <ExternalWrapperSC>
    <SectionSC $isShow={true}>
      {!state && (
        <>
          <ExitButton onClick={props.handlePreviewStep} />
          <TitleSC>Оставьте заявку и наш менеджер свяжется с вами</TitleSC>
          <FormProvider {...method}>
            <FormSC action="#" onSubmit={handleSubmit(onSubmit)}>
              <div style={{ flexGrow: 1 }}>
                <NameInputSC />
                <EmailInputSC />
              </div>
              <ButtonSC type="submit" onClick={handleChangeState}>
                Отправить
              </ButtonSC>
            </FormSC>
          </FormProvider>
        </>
      )}
      {state && (
        <SuccessWrapperSC>
          <SuccessSC>Заявка успешно создана</SuccessSC>
        </SuccessWrapperSC>
      )}
    </SectionSC>
    // </ExternalWrapperSC>
  );
};

const SuccessWrapperSC = styled("div")`
  height: 100%;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const SuccessSC = styled("p")`
  font-size: 24px;
  color: #fff;
`;

const SectionSC = styled.section<{ $isShow?: boolean }>`
  display: flex;
  flex-direction: column;

  border-radius: 16px;

  padding: 8px;

  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(2.5px);

  color: #fff;

  height: 100%;
  width: 100%;
`;

const TitleSC = styled("h4")`
  text-align: center;
  font-size: 1.875rem;
  font-weight: 600;

  margin-top: 2.5rem;
  margin-bottom: 3.5rem;

  @media (max-width: 600px) {
    font-size: 1.4rem;
  }

  @media (max-width: 400px) {
    font-size: 1rem;
  }
`;

const FormSC = styled("form")`
  height: 100%;

  display: flex;
  flex-direction: column;
  /* flex-wrap: wrap; */
  justify-content: space-around;

  /* width: 100%; */
  /* width: 89rem; */
  margin: 0 auto;
`;

const InputSC = styled("input")`
  /*   height: 3rem;
  width: 100%;

  border-radius: 56px;
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(2.5px);

  margin-bottom: 1rem;
  padding: 0.75rem 1.5rem;
  border: none;
  border-bottom: 0.85px solid #000;

  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.5rem;

  color: #000;

  &::placeholder {
    color: rgba(0, 0, 0, 0.4);

    font-size: 1rem;
    font-weight: 600;
    line-height: 1.25rem;
  } */
`;

const ButtonSC = styled("button")`
  border-radius: 56px;
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(2.5px);

  margin: 3rem auto 0;

  width: 15rem;
  height: 3.25rem;

  color: #fff;

  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.5rem;

  cursor: pointer;
`;

const ExitButton = styled("button")`
  position: absolute;

  width: 2rem;
  height: 2rem;

  cursor: pointer;

  background: none;
  background-image: url("ExitButton.svg");
  background-repeat: no-repeat;
`;

export default React.memo(FormBuy);
