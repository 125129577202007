import styled, { css } from "styled-components";

const HeaderSC = styled("header")`
  width: 100%;
  max-width: 2048px;
  position: absolute;

  top: 2rem;
  /* right: 2rem; */

  z-index: 500;
`;

const WrapperHeaderSC = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin: 0 auto;
  width: 95%;

  gap: 2rem;

  @media (max-width: 1220px) {
    justify-content: center;
  }

  @media (max-width: 600px) {
    gap: 0.62rem;

    align-items: start;
    flex-direction: column;
  }
`;

const ContainerElementsSC = styled("div")`
  height: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  align-items: center;

  color: #fff;
`;

const HeaderContactsSC = styled("div")`
  display: flex;
  flex-direction: row;
  gap: 0 72px;
  align-items: center;

  margin-left: auto;

  @media (max-width: 820px) {
    gap: 0 2.19rem;
  }
`;

const SpanSC = styled.span<{ $isOpen: boolean }>`
  border-radius: 56px;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(2.5px);

  /* width: 20rem; */

  color: #fff;

  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.5rem;

  text-align: center;

  padding: 1.25rem 2.5rem;

  @media (max-width: 600px) {
    display: ${({ $isOpen }) => ($isOpen ? "block" : "none")};

    padding: 0.94rem 2rem;
  }

  @media (max-width: 500px) {
    font-size: 0.6rem;
  }
`;

const ContainerSC = styled("div")`
  width: 17.5rem;

  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1890px) {
    margin-left: auto;
  }

  @media (max-width: 768px) {
    margin-left: 0;
    margin: 0 auto;
  }
`;

const ButtonSC = styled("button")`
  position: absolute;
  right: 0.94rem;
  top: -1.1rem;

  display: none;

  background: #000;
  background-image: url("contact_support.svg");
  background-repeat: no-repeat;
  background-position: center center;

  border-radius: 50%;

  cursor: pointer;

  width: 45px;
  height: 45px;

  @media (max-width: 600px) {
    display: block;
  }
`;

const DownContactSC = styled.div<{ $isShow?: boolean }>`
  position: absolute;
  top: 2rem;
  left: 50%;
  transform: translate(-50%, -50%);

  z-index: 10;

  background: rgba(255, 255, 255, 0.1);
  height: 4rem;
  width: 24rem;

  display: ${({ $isShow }) => ($isShow ? "flex" : "none")};
  flex-direction: column;
  justify-content: center;

  @media (max-width: 1890px) {
    top: 0;
    left: 0;
    transform: none;
    /* width: 100%; */
    padding: 0.5rem 0;
    /* display: flex; */
    justify-content: flex-start;
    align-items: flex-start;
  }

  @media (max-width: 768px) {
    top: 6rem;

    left: 50%;
    transform: translate(-50%, -50%);

    width: 100%;
    padding: 0.5rem 0;
    /* display: flex; */
    justify-content: flex-start;
    align-items: flex-start;

    div {
      margin: 0 auto;
    }
  }

  div {
    display: flex;
    flex-direction: column;
    padding: 0.5rem 4.25rem;

    @media (max-width: 368px) {
      padding: 0.5rem 0;
    }

    a {
      color: #fff;
      text-align: center;
      font-size: 0.9375rem;
      font-weight: 600;
      line-height: 1.25rem;

      text-decoration: none;

      /* padding: 1.25rem 0.94rem; */
    }
  }
`;

export const useHeaderStyle = () => ({
  HeaderSC,
  WrapperHeaderSC,
  ContainerElementsSC,
  HeaderContactsSC,
  SpanSC,
  ButtonSC,
  ContainerSC,

  DownContactSC,
});
