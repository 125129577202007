import { useHeaderStyle } from "./HeaderStyles";
import { ReactComponent as BasketIcon } from "../../assets/icon/CartIcon.svg";

import { useHeader } from "./useHeader";

import FormFeedBack from "components/FormFeedBack";
import styled from "styled-components";
import Basket from "../Basket";
import * as process from "process";
import { DISABLE_PAYMENT } from "lib/constants/constants";

const Header = () => {
  const {
    isOpenContact,
    isBasketVisible,
    totalCount,
    toggleContact,
    toggleForm,
    isOpenForm,
    closeForm,
    toggleBasket,
  } = useHeader();

  return (
    <>
      <BasketSC onClick={toggleBasket}>
        <BasketIcon />
        <SpanCountSC>{totalCount}</SpanCountSC>
      </BasketSC>
      <HeaderSC>
        <WrapperHeaderSC>
          <WrapperSC>
            <SpanSC $isOpen={isOpenContact}>
              <a href="mailto:pushkin8765@mail.ru">pushkin8765@mail.ru</a>
            </SpanSC>
            <SpanSC $isOpen={isOpenContact}>
              <a href="tel:+79823488360">+7 982 348 83 60</a>
            </SpanSC>
          </WrapperSC>
        </WrapperHeaderSC>
        <ButtonSC onClick={toggleContact} />
      </HeaderSC>
      {/* <FormBuy isOpenForm={isOpenForm} closeForm={closeForm} /> */}
      <Basket isBasketVisible={isBasketVisible} toggleBasket={toggleBasket} />
    </>
  );
};

const WrapperSC = styled("div")`
  width: 100%;

  display: flex;
  justify-content: right;
  gap: 16px;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const BasketSC = styled("button")`
  z-index: 1000;

  position: fixed;
  top: 30px;
  left: 40px;

  cursor: pointer;

  width: 46px;
  height: 46px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 50%;
  background: rgba(0, 0, 0, 0.6);

  padding: 8px;

  @media (max-width: 600px) {
    top: 15px;
    left: 15px;
  }
`;

const SpanCountSC = styled("span")`
  display: flex;
  justify-content: center;
  align-items: center;

  background: #fff;
  border-radius: 50%;

  position: absolute;
  right: 0;
  bottom: -4px;

  height: 16px;
  width: 16px;
`;

const {
  HeaderSC,
  WrapperHeaderSC,
  ContainerElementsSC,
  HeaderContactsSC,
  SpanSC,
  ButtonSC,
  ContainerSC,
  DownContactSC,
} = useHeaderStyle();

export default Header;
