import { useArrowTopStyles } from "./ArrowTopStyles";

import { useArrowTop } from "./useArrowTop";

const ArrowTop = () => {
  const { isVisible, scrollTop } = useArrowTop();
  return (
    isVisible && (
      <ButtonTopSC onClick={scrollTop}>
        <svg
          width="64"
          height="64"
          viewBox="0 0 64 64"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <g filter="url(#filter0_b_80_2359)">
            <circle cx="32" cy="32" r="32" fill="black" fill-opacity="0.6" />
          </g>
          <mask
            id="mask0_80_2359"
            maskUnits="userSpaceOnUse"
            x="16"
            y="16"
            width="32"
            height="32">
            <rect x="16" y="16" width="32" height="32" fill="#D9D9D9" />
          </mask>
          <g mask="url(#mask0_80_2359)">
            <path
              d="M24.9344 31.5999C24.69 31.3555 24.5677 31.0444 24.5677 30.6666C24.5677 30.2888 24.69 29.9777 24.9344 29.7333L31.0677 23.5999C31.2011 23.4666 31.3455 23.3722 31.5011 23.3166C31.6566 23.2611 31.8233 23.2333 32.0011 23.2333C32.1789 23.2333 32.3455 23.2611 32.5011 23.3166C32.6566 23.3722 32.8011 23.4666 32.9344 23.5999L39.0677 29.7333C39.3122 29.9777 39.44 30.2833 39.4511 30.6499C39.4622 31.0166 39.3344 31.3333 39.0677 31.5999C38.8233 31.8444 38.5122 31.9666 38.1344 31.9666C37.7566 31.9666 37.4455 31.8444 37.2011 31.5999L32.0011 26.4333L26.8011 31.5999C26.5566 31.8444 26.2511 31.9722 25.8844 31.9833C25.5177 31.9944 25.2011 31.8666 24.9344 31.5999ZM24.9344 39.5999C24.69 39.3555 24.5677 39.0444 24.5677 38.6666C24.5677 38.2888 24.69 37.9777 24.9344 37.7333L31.0677 31.5999C31.2011 31.4666 31.3455 31.3722 31.5011 31.3166C31.6566 31.2611 31.8233 31.2333 32.0011 31.2333C32.1789 31.2333 32.3455 31.2611 32.5011 31.3166C32.6566 31.3722 32.8011 31.4666 32.9344 31.5999L39.0677 37.7333C39.3122 37.9777 39.44 38.2833 39.4511 38.6499C39.4622 39.0166 39.3344 39.3333 39.0677 39.5999C38.8233 39.8444 38.5122 39.9666 38.1344 39.9666C37.7566 39.9666 37.4455 39.8444 37.2011 39.5999L32.0011 34.4333L26.8011 39.5999C26.5566 39.8444 26.2511 39.9722 25.8844 39.9833C25.5177 39.9944 25.2011 39.8666 24.9344 39.5999Z"
              fill="white"
              fill-opacity="0.8"
            />
          </g>
          <defs>
            <filter
              id="filter0_b_80_2359"
              x="-5"
              y="-5"
              width="74"
              height="74"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feGaussianBlur in="BackgroundImageFix" stdDeviation="2.5" />
              <feComposite
                in2="SourceAlpha"
                operator="in"
                result="effect1_backgroundBlur_80_2359"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_backgroundBlur_80_2359"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      </ButtonTopSC>
    )
  );
};

const { ButtonTopSC } = useArrowTopStyles();

export default ArrowTop;
