import styled from "styled-components";

const TextContainerSC = styled("div")`
  z-index: 10;
  position: relative;

  display: flex;

  user-select: none;
  /* z-index: 1; */

  width: 50%;
  height: 100%;

  color: #fff;

  @media (max-width: 1220px) {
    width: 100%;
  }
`;

const TitleCompanySC = styled("h1")`
  font-size: 3rem;
  font-weight: 700;

  margin-bottom: 1.5rem;

  @media (max-width: 768px) {
    font-size: 2.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: 3.4375rem;
  }

  @media (max-width: 375px) {
    font-size: 2.1875rem;
    font-style: normal;
    font-weight: 700;
    line-height: 2.8125rem;
  }
`;

const ContainerSC = styled("div")`
  display: flex;
  flex-direction: column;

  justify-content: center;

  //width: 34.5rem;
  width: 38.5rem;
  padding-left: 7.5rem;

  @media (max-width: 1326px) {
    padding-left: 0;
    margin: 0 auto;
    text-align: center;
  }

  @media (max-width: 442px) {
    padding-bottom: 4.8rem;
  }
`;

const DescriptionCompanySC = styled("ul")`
  margin: 0 auto;
  margin-bottom: 2.5rem;
  list-style-type: circle;

  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.5rem;

  @media (max-width: 1220px) {
    width: 85%;
    margin: 0 auto 2.5rem;
  }

  @media (max-width: 768px) {
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.25rem;

    width: 70%;
  }

  @media (max-width: 375px) {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.25rem;
  }
`;

const LiSC = styled.li`
  list-style-type: circle;
  text-align: left;
`;

const ButtonSC = styled("button")`
  width: 17.5rem;
  height: 4rem;

  color: #fff;

  cursor: pointer;

  border-radius: 56px;
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(2.5px);

  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.5rem;

  @media (max-width: 1326px) {
    margin: 0 auto;
  }
`;

const ButtonGroupSC = styled("div")`
  display: flex;
  gap: 16px;
  @media (max-width: 860px) {
    display: flex;
    flex-direction: column;
  }
`;

export const useAboutCompanyStyles = () => ({
  TextContainerSC,
  TitleCompanySC,
  DescriptionCompanySC,
  ContainerSC,
  ButtonGroupSC,
  ButtonSC,
  LiSC,
});
