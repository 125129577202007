import React from "react";
import styled from "styled-components";
import { ReactComponent as DeleteIcon } from "../../../assets/icon/deleteIcon.svg";

import { BASE_URL } from "../../../lib/constants/constants";
import useStore from "../../../store";

const BasketCard = (props) => {
  const { removeItem, decreaseQuantity, increaseQuantity, setQuantity } =
    useStore();

  return (
    <ContainerSC>
      <OutterSC>
        <ImgSC src={`${BASE_URL}/${props.image}`} />
        <WrapperSC>
          <div>
            <TitleSC>{props.name}f</TitleSC>
            <VendorSC>{props.vendor_code}</VendorSC>
          </div>
          <PriceSC>{props.price} ₽</PriceSC>
        </WrapperSC>
        <ActionWrapperSC>
          <CountContainerSC>
            <SplitButtonSC onClick={() => decreaseQuantity(props)}>
              -
            </SplitButtonSC>
            <CountSC
              type={"number"}
              value={props.quantity}
              onChange={(e) => setQuantity(Number(e.target.value), props)}
              onBlur={(e) => {
                e.target.value === "0" && removeItem(props);
              }}
            />
            <SplitButtonSC onClick={() => increaseQuantity(props)}>
              +
            </SplitButtonSC>
          </CountContainerSC>
          <ButtonSC onClick={() => removeItem(props)}>
            <DeleteIcon />
          </ButtonSC>
        </ActionWrapperSC>
      </OutterSC>
    </ContainerSC>
  );
};

const ContainerSC = styled("li")`
  color: white;
`;

const OutterSC = styled("div")`
  display: flex;
`;

const WrapperSC = styled("div")`
  flex-grow: 1;

  margin-right: 8px;

  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const VendorSC = styled("h4")`
  @media (max-width: 500px) {
    font-size: 13px;
  }

  @media (max-width: 400px) {
    font-size: 8px;
  }
`;

const ImgSC = styled("img")`
  display: block;

  object-fit: cover;

  width: 100px;
  //height: 100px;
  /* height: 15rem; */

  margin-right: 1.5rem;

  @media (max-width: 500px) {
    width: 80px;
    //height: 80px;
  }

  @media (max-width: 400px) {
    width: 60px;
    //height: 60px;
  }
`;

const ActionWrapperSC = styled("div")`
  justify-content: center;
  align-items: center;

  gap: 16px;

  //margin: 0 14px;

  //flex-grow: 1;
  display: flex;
  //align-items: end;
  //justify-content: space-between;
  //flex-direction: column;
`;

const ButtonSC = styled("button")`
  cursor: pointer;
  background: none;

  width: 24px;
  height: 24px;

  svg {
    width: 22px;
    height: 22px;

    fill: #fff;

    @media (max-width: 500px) {
      width: 20px;
      height: 20px;
    }

    @media (max-width: 400px) {
      width: 16px;
      height: 16px;
    }
  }
`;

const TitleSC = styled("h6")`
  font-size: 16px;

  flex-grow: 1;

  @media (max-width: 500px) {
    font-size: 13px;
  }

  @media (max-width: 400px) {
    font-size: 11px;
  }
`;

const CountContainerSC = styled("div")`
  display: flex;
  //flex-direction: column;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const CountSC = styled("input")`
  text-align: center;
  width: 20px;
  color: white;
  background: none;
  border: 1px solid white;
  border-radius: 8px;

  &[type="number"] {
    -moz-appearance: textfield;
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }
  &:focus {
    outline: none;
  }
`;

const SplitButtonSC = styled("button")`
  width: 20px;
  height: 20px;

  cursor: pointer;

  font-weight: 600;
  font-size: 16px;
  //color: #;
  border-radius: 16px;
  background-color: #fff;
  //background: none;
`;

const PriceSC = styled("p")``;

export default React.memo(BasketCard);
