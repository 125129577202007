import styled from "styled-components";

const ExternalWrapperSC = styled("div")`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;

  @media (min-width: 1890px) {
    display: none;
  }
`;

const SectionSC = styled.section<{ $isShow?: boolean }>`
  display: ${({ $isShow }) => ($isShow ? "block" : "none")};

  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(2.5px);

  color: #fff;

  z-index: 1000;

  position: absolute;
  top: 12rem;
  right: 25%;
  transform: translate(50%, 0%);

  width: 27rem;
  /* height: 31.75rem; */
  padding: 1.5rem;

  /* @media (max-width: 812px) {
    width: calc(100% - 15px * 2);
    height: 50%;

    top: 6rem;

    margin: 0 auto;

    padding: 2rem;
  } */

  @media (max-width: 1220px) {
    backdrop-filter: blur(10px);
    right: 50%;
  }
  @media (max-width: 442px) {
    top: 0;
    /* height: 80%; */
    height: 100%;
    width: 100%;
  }
`;

const TitleSC = styled("h4")`
  text-align: center;
  font-size: 1.875rem;
  font-weight: 600;
  line-height: 2.125rem;

  margin-top: 2.5rem;
  margin-bottom: 3.5rem;
`;

const FormSC = styled("form")`
  display: flex;
  flex-direction: column;
  /* flex-wrap: wrap; */
  justify-content: space-around;

  /* width: 100%; */
  /* width: 89rem; */
  margin: 0 auto;
`;

const InputSC = styled("input")`
  /*   height: 3rem;
  width: 100%;

  border-radius: 56px;
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(2.5px);

  margin-bottom: 1rem;
  padding: 0.75rem 1.5rem;
  border: none;
  border-bottom: 0.85px solid #000;

  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.5rem;

  color: #000;

  &::placeholder {
    color: rgba(0, 0, 0, 0.4);

    font-size: 1rem;
    font-weight: 600;
    line-height: 1.25rem;
  } */
`;

const ButtonSC = styled("button")`
  border-radius: 56px;
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(2.5px);

  margin: 3rem auto 0;

  width: 15rem;
  height: 3.25rem;

  color: #fff;

  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.5rem;

  cursor: pointer;

  @media (max-width: 320px) {
    width: 100%;
    /* max-width: 50%; */
    /* margin: 0 auto; */
  }
`;

const ExitButton = styled("button")`
  position: absolute;

  width: 2rem;
  height: 2rem;

  cursor: pointer;

  background: none;
  background-image: url("ExitButton.svg");
  background-repeat: no-repeat;
`;

export const useFormFeedBackStyles = () => ({
  SectionSC,
  FormSC,
  TitleSC,
  InputSC,
  ButtonSC,
  ExternalWrapperSC,
  ExitButton,
});
