import { useEffect, useRef, useState } from "react";

import { useProductsStyles } from "./ProductsStyles";
import ProductCard from "components/ProductCard";
import { useProducts } from "./useProducts";
import FullVisionCard from "components/ProductCard/FullVision";
import useStore from "../../store";

const Products = () => {
  const { divRef, selectedProduct, setSelectedProduct, cards } = useProducts();
  const { data } = useStore();

  // const divRef = useRef(null);
  // const [selectedProduct, setSelectedProduct] = useState(null);

  /* function handleClick(props) {
    setSelectedProduct(props);
  }

  useEffect(() => {
    const div = divRef.current;

    const windowSize = () => {
      if (window.innerWidth <= 812) {
        div.addEventListener("click", handleClick);
      } else {
        div.removeEventListener("click", handleClick);
      }
    };
    window.addEventListener("resize", windowSize);
    return () => window.removeEventListener("resize", windowSize);
  }, []); */

  // const CasperElements = cards.map((el, i) => (
  //   <ProductCard {...el} key={el.id} />
  // ));

  const CasperElements = cards.map((item, i) => {
    const isAdded = data.some((itemBask) => itemBask.name === item.title);

    return <ProductCard key={item.id} isAdded={isAdded} {...item} />;
  });

  return (
    <>
      <ProductsSC>
        <ProductsTitleSC>Продукция</ProductsTitleSC>
        <ProductContainerSC ref={divRef}>{CasperElements}</ProductContainerSC>
      </ProductsSC>
      <TextContainerSC style={{ flexDirection: "column" }}>
        <SubtitleSC>
          Адрес: г. Челябинск, ул. Братьев Кашириных, д. 32, цоколь
        </SubtitleSC>
        <SubtitleSC>ИП Пушкин Ярослав Сергеевич</SubtitleSC>
        <SubtitleSC>ИНН: 660205793468</SubtitleSC>
      </TextContainerSC>
    </>
  );
};

const {
  ProductsSC,
  ProductsTitleSC,
  TextContainerSC,
  SubtitleSC,
  ProductContainerSC,
} = useProductsStyles();

export default Products;
