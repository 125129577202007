import React, { useRef } from "react";
import emailjs from "emailjs-com";

export const useEmailJS = () => {
  // ! настоящие данные для отправки формы закомичены

  /*   const serviceId = "service_oinh39p";
  const templateId = "template_n14tphg";
  const publickKey = "f1GwrOJ84DC7x7Jzp"; */
  const serviceId = "service_jjwmft1";
  const templateId = "template_wqavs4f";
  const publickKey = "iv7BBc2eMu3SZqEF7";
  const form = useRef();

  const onFetchMail = async (formValue: any) => {
    return await emailjs
      .send(serviceId, templateId, formValue, publickKey)
      .then(
        (result) => {},
        (error) => {
          return error;
        },
      );
  };
  return { onFetchMail };
};
