import React, { useState } from "react";
import styled from "styled-components";
import useStore from "../../store";
import BasketCard from "./BasketCard";
import { createPortal } from "react-dom";
import FormBuy from "./FormBuy";
import { ReactComponent as CloseIcon } from "../../assets/icon/CloseIcon.svg";
import { DISABLE_PAYMENT } from "../../lib/constants/constants";

const Basket = ({ isBasketVisible, toggleBasket }) => {
  const { removeAll } = useStore();
  const [isNextStep, setIsNextStep] = useState(false);
  const { data } = useStore();

  const totalPrice = data.reduce(
    (accumulator, item) => accumulator + Number(item.price) * item.quantity,
    0,
  );

  const handleNextStep = () => {
    setIsNextStep(true);
  };

  const handlePreviewStep = () => {
    setIsNextStep(false);
  };

  const elements = data.map((item, i) => <BasketCard {...item} />);

  return (
    <>
      {isBasketVisible &&
        createPortal(
          <ContainerSC>
            {!isNextStep && (
              <>
                {data.length > 0 && (
                  <>
                    <ScrollContainerSC>
                      <TopContainerSC>
                        <WrapperSC>
                          <TitleSC>Корзина</TitleSC>

                          <ButtonRemoveAll onClick={removeAll}>
                            Очистить корзину
                          </ButtonRemoveAll>
                        </WrapperSC>
                        <ButtonsContainerSC>
                          <CloseBasketSC onClick={toggleBasket}>
                            <CloseIcon />
                          </CloseBasketSC>
                        </ButtonsContainerSC>
                      </TopContainerSC>
                      <ListSC>{elements}</ListSC>
                    </ScrollContainerSC>
                    <ItogoSC>
                      <SubtitleSC>Общая сумма заказа:</SubtitleSC>
                      <TitleSC>{totalPrice} ₽</TitleSC>
                    </ItogoSC>
                    <ActionContainerSC>
                      {DISABLE_PAYMENT === "true" ? (
                        <ButtonSC onClick={handleNextStep}>Заказать</ButtonSC>
                      ) : (
                        <a
                          href={`https://payment.alfabank.ru/payment/constructor/prepay.html?login=r-motortech&logo=0&def={"name":"amount","value":"${totalPrice}","title":"motortech.site"}&currency[]=RUR`}>
                          <ButtonSC>Перейти к оплате</ButtonSC>
                        </a>
                      )}
                    </ActionContainerSC>
                  </>
                )}
                {!data.length && (
                  <EmptyContainerSC>
                    <ButtonsContainerSC>
                      <CloseBasketSC onClick={toggleBasket}>
                        <CloseIcon />
                      </CloseBasketSC>
                    </ButtonsContainerSC>
                    <EmptyContSC>
                      <EmptyTitleSC>Корзина пуста</EmptyTitleSC>
                    </EmptyContSC>
                  </EmptyContainerSC>
                )}
              </>
            )}
            {isNextStep && (
              <>
                <FormBuy handlePreviewStep={handlePreviewStep} />
              </>
            )}
          </ContainerSC>,
          document.querySelector("#root") as HTMLElement,
        )}
    </>
  );
};

const SubtitleSC = styled("p")`
  font-size: 14px;
  color: #fff;
  font-weight: 700;
`;

const ItogoSC = styled("div")`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const WrapperSC = styled("div")`
  display: flex;
  flex-direction: column;
`;

const ScrollContainerSC = styled("div")`
  flex-grow: 1;

  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const TitleSC = styled("h4")`
  font-size: 18px;

  margin-bottom: 16px;
  color: #fff;
`;

const ButtonsContainerSC = styled("div")`
  display: flex;
  flex-direction: column;
`;

const CloseBasketSC = styled("div")`
  cursor: pointer;

  display: flex;
  justify-content: end;

  margin-bottom: 16px;

  svg {
    width: 32px;
    height: 32px;
  }
`;

const ButtonRemoveAll = styled("button")`
  text-align: left;

  cursor: pointer;

  background: none;
  color: #fff;
`;

const TopContainerSC = styled("div")`
  display: flex;
  justify-content: space-between;

  margin-bottom: 16px;
`;

const EmptyContainerSC = styled("div")`
  height: 100%;

  display: flex;
  flex-direction: column;
  //justify-content: center;
  //align-items: center;
`;

const EmptyContSC = styled("div")`
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const EmptyTitleSC = styled("h4")`
  align-self: center;
  justify-content: center;

  font-size: 24px;

  color: #fff;
`;

const ContainerSC = styled("div")`
  display: flex;
  flex-direction: column;

  //min-height: 600px;
  //max-height: 600px;
  height: 600px;
  width: 50%;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  padding: 16px;

  background: #292929;
  border-radius: 16px;
  z-index: 100000;

  @media (max-width: 900px) {
    height: 90%;
    width: 90%;
  }
`;

const ListSC = styled("ul")`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  gap: 24px;
`;

const ActionContainerSC = styled("div")`
  text-align: center;
`;

const ButtonSC = styled("button")`
  cursor: pointer;

  border-radius: 16px;

  //height: 40px;
  height: 3.25rem;
  width: 100%;

  font-size: 16px;
  color: #fff;

  //margin-top: 48px;

  background: rgba(255, 255, 255, 0.4);
  -webkit-backdrop-filter: blur(2.5px);
  backdrop-filter: blur(2.5px);
  font-weight: 600;
  line-height: 1.5rem;
`;

export default React.memo(Basket);
