import styled from "styled-components";
import { InputSC } from "UI/InputUI/style";

const InputFullName = styled(InputSC)`
  /* margin-bottom: 27px; */
  height: 3rem;
  width: 100%;

  border-radius: 56px;
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(2.5px);

  margin-bottom: 1rem;
  padding: 0.75rem 1.5rem;
  border: none;
  border-bottom: 0.85px solid #000;

  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.5rem;

  color: #000;

  &::placeholder {
    color: rgba(0, 0, 0, 0.4);

    font-size: 1rem;
    font-weight: 600;
    line-height: 1.25rem;
  }
`;

export const useFullNameStyle = () => ({
  InputFullName,
});
