import { useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
// import { useEmailJS } from "../../../hooks/useEmailJS";
import { useEmailJS } from "hooks/useEmailJS";
// import { useMobile } from "../../../hooks/useMediaQuery";

export const useFormFeedback = (closeForm) => {
  const method = useForm();
  // const isMobile = useMobile();
  const { control, handleSubmit } = method;
  const [isError, setError] = useState<boolean | undefined | void>(false);
  const { onFetchMail } = useEmailJS();
  const onSubmit = async (data: FieldValues) => {
    setError(false);
    const mailData = {
      name: data.name,
      email: data.email,
      theme: data.theme,
    };
    const error = await onFetchMail(mailData);
    setError(error);

    // ? если нет ошибок, очищаем инпуты
    if (!error) {
      method.reset();
      if (closeForm) {
        closeForm();
      }
    }
  };
  return { method, isError, control, onSubmit, handleSubmit };
};
