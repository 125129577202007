import styled from "styled-components";

const WrapperSC = styled("div")`
  width: 100%;

  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    max-width: 360px;
  }
`;

const CardSC = styled("article")`
  width: 32rem;
  height: 11rem;
  cursor: pointer;

  overflow: hidden;

  display: flex;

  background-color: #f5f6f8;

  &:hover {
    background-color: #fff;
  }

  transition: all 0.3s linear;

  @media (max-width: 1920px) {
    /* width: 28rem; */
    width: 100%;
  }
  @media (max-width: 1440px) {
    max-width: 22.5rem;
  }
  @media (max-width: 1224px) {
    /* width: 50%; */
    width: 100%;
    height: 314px;
    /* max-width: 29rem; */
    flex-direction: column;
  }
`;

const ImgSC = styled("img")`
  display: block;

  object-fit: contain;

  width: 13.9rem;
  /* height: 15rem; */

  margin-right: 1.5rem;

  @media (max-width: 1440px) {
    width: 10.5rem;
  }

  @media (max-width: 1224px) {
    width: 100%;
    margin-right: 0;
    object-fit: cover;

    max-width: none;
    height: 10rem;

    flex-direction: column;
  }
`;

const InfoContainerSC = styled("div")`
  //firefox
  padding: 0.5rem;
  padding-left: 0;

  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;

  /* padding-bottom: 20px; */
  /* max-height: 232px; */
  /* max-height: 205px; */
  /* height: 205px; */

  @media (max-width: 1224px) {
    padding: 1rem 5px;
    padding-bottom: 0;

    display: flex;
    flex-direction: column;
  }

  @media (max-width: 812px) {
    flex: 1 1 auto;
    justify-content: space-around;
    /* overflow-y: hidden;

    display: -webkit-box;
    -webkit-line-clamp: 8;
    -webkit-box-orient: vertical; */
  }
`;

const NumberContainerSC = styled("div")`
  padding: 5px;

  display: none;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1224px) {
    display: flex;

    flex: 0 0 auto;
  }
`;

const NumberContainerSCDekstop = styled("div")`
  /* display: none; */

  @media (max-width: 1224px) {
    display: none;
  }
`;

const TitleSC = styled("h5")`
  margin-bottom: 0.25rem;

  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.5rem;
  height: 3rem;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 1224px) {
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.25rem;

    height: auto;

    margin-bottom: 0.31rem;
  }
  @media (max-width: 375px) {
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.25rem;
  }
`;

const DescriptionSC = styled("p")`
  overflow: hidden;

  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;

  @media (max-width: 1224px) {
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.0625rem;

    -webkit-line-clamp: 3;
  }

  @media (max-width: 768px) {
    -webkit-line-clamp: 4;
  }
  @media (max-width: 375px) {
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 600;
    line-height: 0.9375rem;
  }
`;

const VendorCodeSC = styled("p")`
  color: #6f6f6f;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5rem;

  @media (max-width: 1224px) {
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: 0.9375rem;
  }
  @media (max-width: 812px) {
    font-size: 0.625rem;
    font-weight: 700;
    line-height: 0.9375rem;
  }
`;

const PriceSC = styled("p")`
  color: #0c0e20;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5rem;

  @media (max-width: 1224px) {
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.25rem;
  }
  @media (max-width: 812px) {
    font-size: 0.9375rem;
    font-weight: 700;
    line-height: 1.25rem;
  }
`;

export const useProductCardStyles = () => ({
  CardSC,
  ImgSC,
  InfoContainerSC,
  NumberContainerSC,
  TitleSC,
  DescriptionSC,
  VendorCodeSC,
  PriceSC,
  NumberContainerSCDekstop,
  WrapperSC,
});
