import styled from "styled-components";

const WrapperSC = styled("div")`
  position: relative;
  max-width: 980px;
  width: 90%;

  display: flex;
  flex-direction: column;
  /* min-height: 200px; */
  /* max-height: 50vh; */
  height: 50vh;

  /* min-height: 42vh; */
  /* background-color: #fff; */
  padding: 0.94rem;

  color: #f1f1f1;

  /* box-shadow: 0 0 20px rgba(0, 0, 0, 0.5); */

  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(2.5px);

  @media (max-width: 768px) {
    width: 75%;
  }
  @media (max-width: 375px) {
    width: 90%;
  }
`;

const VendorCodeSC = styled("h4")`
  color: #fff;
  font-size: 0.8125rem;
  font-weight: 700;
  line-height: 0.9375rem;
`;

const PriceSC = styled("h3")`
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.5rem;
`;

const TitleSC = styled("h2")`
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.5rem;

  margin-bottom: 1.62rem;

  width: 85%;
`;

const DescriptionSC = styled("p")`
  font-size: 0.875rem;
  line-height: 1.25rem;

  ::-webkit-scrollbar {
    width: 8px; /*Ширина скроллбара*/
  }

  ::-webkit-scrollbar-thumb {
    background-color: #e4e6eb;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  /*   overflow-y: scroll;
  padding-bottom: 20px;
  height: 85%;

  @media (max-width: 626px) {
    height: 80%;
  } */
`;

const ExternalWrapperSC = styled("div")`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const ButtonExit = styled("button")`
  background: none;
  background-image: url("ExitButton.svg");
  background-repeat: no-repeat;

  width: 1.87rem;
  height: 1.87rem;

  position: absolute;
  top: 0.94rem;
  right: 0.94rem;

  cursor: pointer;
`;

const ContainerRow = styled("div")`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0.5rem;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FlexGrow = styled("div")`
  /* flex: 1; */

  flex: 1 0 auto;

  ::-webkit-scrollbar {
    width: 8px; /*Ширина скроллбара*/
  }

  ::-webkit-scrollbar-thumb {
    background-color: #e4e6eb;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  overflow-y: auto;
  padding-bottom: 20px;
  /* height: 85%; */
  /* max-height: 85%; */

  flex: 1 0 auto;

  max-height: calc(85% - 2.5rem); /* Subtract the height of ContainerRow */
  overflow-y: auto;

  @media (max-width: 771px) {
    max-height: calc(80% - 2.5rem); /* Adjust the height for smaller screens */
  }

  @media (max-width: 534px) {
    max-height: calc(80% - 3.5rem); /* Adjust the height for smaller screens */
  }
  @media (max-width: 404px) {
    max-height: calc(80% - 5.5rem); /* Adjust the height for smaller screens */
  }
`;

export const useFullVisionStyle = () => ({
  WrapperSC,
  PriceSC,
  TitleSC,
  VendorCodeSC,
  DescriptionSC,
  ExternalWrapperSC,
  ButtonExit,
  ContainerRow,
  FlexGrow,
});
