// @ts-nocheck
import { useState } from "react";

import { useAboutCompanyStyles } from "./AboutCompany";

import FormFeedBack from "components/FormFeedBack";
import Requisites from "./Requisites/Requisites";

type ModalState = "contacts" | "requisites" | "About" | "basket" | "";

const AboutCompany = () => {
  const [isOpenForm, setIsOpenForm] = useState(false);

  const closeForm = () => {
    setIsOpenForm(false);
  };

  const [requisites, setRequisites] = useState<ModalState>("");

  const handleRequisites = (data: ModalState) => {
    return () => {
      setRequisites(data);
    };
  };

  return (
    <>
      <TextContainerSC>
        <ContainerSC>
          <TitleCompanySC>Моторы и оборудование для спецтехники</TitleCompanySC>
          <DescriptionCompanySC>
            <LiSC>Большой выбор запчастей для спецтехники</LiSC>
            <LiSC>Команда проффесионалов с многолетним опытом</LiSC>
            <LiSC>Подбор запчастей под индивидуальные запросы клиента</LiSC>
          </DescriptionCompanySC>
          <ButtonGroupSC>
            <ButtonSC onClick={() => setIsOpenForm(!isOpenForm)}>
              Обратная связь
            </ButtonSC>
            <ButtonSC onClick={() => setRequisites("requisites")}>
              Покупателям
            </ButtonSC>
          </ButtonGroupSC>
        </ContainerSC>
      </TextContainerSC>
      <FormFeedBack isOpenForm={isOpenForm} closeForm={closeForm} />
      <Requisites
        isOpen={requisites === "requisites"}
        handleClose={handleRequisites}
        className="req"
      />
    </>
  );
};

const {
  TextContainerSC,
  DescriptionCompanySC,
  TitleCompanySC,
  ContainerSC,
  ButtonGroupSC,
  ButtonSC,
  LiSC,
} = useAboutCompanyStyles();

export default AboutCompany;
