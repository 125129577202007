import { useState, useEffect } from "react";
import useStore from "../../store";

export const useHeader = () => {
  const { totalCount } = useStore((state) => {
    const dataLength = state.data.length; // Длина массива data

    // Подсчет общего количества элементов (сумма quantity всех элементов)
    const totalCount = state.data.reduce(
      (total, item) => total + item.quantity,
      0,
    );

    return {
      dataLength,
      totalCount,
    };
  });
  const [isOpenContact, setIsOpenContact] = useState(false);
  const [isOpenForm, setIsOpenForm] = useState(false);
  const [isBasketVisible, setIsBasketVisible] = useState(false);

  const toggleContact = () => {
    setIsOpenContact(!isOpenContact);
  };

  const toggleForm = () => {
    setIsOpenForm(!isOpenForm);
  };

  const closeForm = () => {
    setIsOpenForm(false);
  };

  const toggleBasket = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setIsBasketVisible(!isBasketVisible);
  };

  useEffect(() => {
    const windowSize = () => {
      if (window.innerWidth <= 812) {
      } else {
      }
    };
    window.addEventListener("resize", windowSize);
    return () => window.removeEventListener("resize", windowSize);
  }, []);

  return {
    toggleForm,
    isOpenContact,
    isOpenForm,
    isBasketVisible,
    totalCount,

    toggleContact,
    closeForm,
    toggleBasket,
  };
};
